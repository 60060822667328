<template>
  <div class="historyOfPurchaseOrders">
    <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
      <DataCard
        :data="allFilters"
        :fields="fields"
        @cardClick="detailOrder"
        @filterR="getAPI"
        filterData
      />
    </q-pull-to-refresh>
  </div>
</template>

<script>
import DataCard from "../../components/DataCard";
import { Loading, QSpinnerHourglass } from "quasar";
import { mapActions } from "vuex";

export default {
  props: ["orders", "id"],
  components: {
    DataCard
  },
  data() {
    return {
      fields: [
        {
          field: "supplier",
          label: "",
          type: "string",
          class: "text-bold"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "branch",
          label: "",
          type: "string",
          class: "text-body2"
        },
        {
          field: "amount",
          label: "",
          type: "currency",
          class: "text-overline"
        }
      ],
      userProtheus: this.$store.state.users.loggedInUser.protheusUserCode,
      ordersAPI: [],
      filterWebR: null,
      filterR: null
    };
  },
  computed: {
    allFilters() {
      let orders = this.ordersAPI;

      if (this.filterB) {
        if (sessionStorage.filterWebB) {
          orders = orders.filter(ord => {
            return ord.branchCode.indexOf(sessionStorage.filterWebB) >= 0;
          });
        } else {
          orders = orders.filter(ord => {
            return ord.branchCode.indexOf(this.filterB) >= 0;
          });
        }
      }

      orders = orders.map(order => {
        return {
          id: `${order.cnpj}`,
          supplier: `${order.orderNumber} - ${order.supplierName}`,
          branch: `${order.branchCode} - ${order.branchName}`,
          amount: order.amountOfOrder,
          issueDate: order.issueDate,
          currency: order.currency,
          orderNumber: order.orderNumber,
          status: order.status
        };
      });

      return orders;
    },
    getDatas() {
      return this.ordersAPI;
    }
  },
  methods: {
    ...mapActions("historyOrders", ["getHistory"]),
    detailOrder(historyID) {
      this.$router.push({
        name: "historyDetail",
        params: {
          id: historyID,
          order: this.filterR
        }
      });
    },
    async getAPI(filterR) {
      Loading.show({
        message: "Buscando o Pedido de Compra informado. Aguarde...",
        backgroundColor: "primary",
        messageColor: "white text-bold",
        spinner: QSpinnerHourglass,
        spinnerColor: "white"
      });

      this.filterR = filterR;

      if (
        this.filterR.length < 6 ||
        this.filterR == null ||
        this.filterR == ""
      ) {
        // return null;
      } else {
        let response = await this.getHistory(filterR);

        this.ordersAPI = response;
      }

      Loading.hide();
    },
    seeHistory(history) {
      this.history = history;
    },
    async refresh(done) {
      // await this.getOrders();
      done();
    }
  }
};
</script>
<style lang="scss">
.approvalOfPurchaseOrders {
  width: 100%;
}
</style>
